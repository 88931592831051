<template>
  <div class="property-page view-page">
    <c-search searchTitle="文件名称" @search="clientSearchKey" placeholder="请输入关键字"></c-search>
    <c-navigation title="财产状态" :list="navigation" :isBorder="true" @change="changePropertyState"></c-navigation>
    <c-operate>
      <template #right>
        <div class="btn-group">
          <div class="btn-list transfer" @click="openTransfer">移交</div>
          <div class="btn-list add" @click="openAdd">新增</div>
        </div>
      </template>
    </c-operate>
    <c-table :data="tableData">
      <el-table-column align="center" prop="date" label="选择">
        <template #default="{ row }">
          <el-checkbox @change="changeNo(row)"></el-checkbox>
          {{ row.date }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="title" label="财产名称"> </el-table-column>
      <el-table-column align="center" prop="num" label="财产数量"> </el-table-column>
      <el-table-column align="center" prop="username" label="负责人"> </el-table-column>
      <el-table-column align="center" prop="mobile" label="手机号"> </el-table-column>
      <el-table-column align="center" label="部门">
        <template #default="{ row }">
          <span>{{ $store.state.departmentObj[row.section_id] }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="remark" label="财产备注"> </el-table-column>
      <el-table-column align="center" prop="status" label="财产状态"> </el-table-column>
      <el-table-column align="center" label="创建时间">
        <template #default="{ row }">
          <p>{{ moment.unix(row.createtime).format('YYYY-MM-DD HH:mm:ss') }}</p>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="date" label="操作" width="150">
        <template #default="{ row }">
          <div class="table-operate">
            <p class="operate-list" @click="editAssets(row)">编辑</p>
            <!-- <p class="operate-list" @click="isTransferInfo = true">移交信息</p> -->
          </div>
        </template>
      </el-table-column>
    </c-table>
    <!-- 新增或编辑财产 -->
    <c-dialog v-model="isAddOrEdit" :title="addOrEditType" @confirm="addOrEditConfirm">
      <div class="newAdd">
        <el-form :inline="true" :model="addOrEditParam" label-width="80px" size="small" class="demo-form-inline">
          <el-row>
            <el-col :span="12">
              <el-form-item label="财产名称：" label-width="5rem">
                <el-input v-model="addOrEditParam.title" placeholder="请输入财产名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="财产数量：" label-width="5rem">
                <el-input v-model="addOrEditParam.num" placeholder="请输入财产数量"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="选择部门：" label-width="5rem">
                <el-select v-model="addOrEditParam.section_id" placeholder="请选择" @change="changeAddSection">
                  <el-option v-for="item in $store.state.departmentList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="负责人：" label-width="5rem">
                <el-select v-model="addOrEditParam.admin_id" placeholder="请选择" @change="changeAddHead">
                  <el-option v-for="item in headList" :key="item.id" :label="item.username" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式：" label-width="5rem">
                <el-input v-model="addOrEditParam.mobile" disabled placeholder="联系方式"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="移交材料：" label-width="5rem">
                <el-input type="textarea" :rows="3" resize="none" placeholder="请输入内容" v-model="addOrEditParam.remark"> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="财产状态：" label-width="5rem">
                <el-select v-model="addOrEditParam.status" placeholder="请选择">
                  <el-option v-for="item in navigation" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </c-dialog>
    <!-- 移交财产 -->
    <c-dialog v-model="isTransfer" title="移交财产" @confirm="confirmTransfer">
      <el-form :inline="true" :model="transferParam" label-width="80px" size="small" class="demo-form-inline">
        <el-row>
          <el-col :span="12">
            <el-form-item label="选择部门：" label-width="5rem">
              <el-select v-model="transferParam.section_id" placeholder="请选择" @change="changeTransferSection">
                <el-option v-for="item in $store.state.departmentList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="负责人：" label-width="5rem">
              <el-select v-model="transferParam.admin_id" @change="changeTransferHead" placeholder="请选择">
                <el-option v-for="item in headList" :key="item.id" :label="item.username" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系方式：" label-width="5rem">
              <el-input v-model="transferParam.mobile" disabled placeholder="联系方式"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="移交材料：" label-width="5rem">
              <el-input type="textarea" :rows="3" resize="none" placeholder="请输入内容" v-model="transferParam.remark"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </c-dialog>
    <!-- 移交信息 -->
    <c-dialog v-model="isTransferInfo" title="" :cancel="false" :save="false">
      <div class="transferInfo">
        <div class="data">
          <!-- <span class="txt">时间：</span> -->
          <c-search :isSearch="false" @search="clientSearchDate" :isBorder="false" :isDate="true"></c-search>
          <!-- <el-date-picker v-model="datePickerValue" size="small" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['12:00:00']"> </el-date-picker> -->
        </div>
        <div class="table-container">
          <c-table :data="transferInfoList" :isPadding="false">
            <el-table-column align="center" prop="username" label="移交部门">
              <template #default="{ row }">
                <span>{{ $store.state.departmentObj[row.section_id] }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="username" label="移交人"> </el-table-column>
            <el-table-column align="center" prop="remark" label="交备注"> </el-table-column>
            <el-table-column align="center" prop="createtime" label="移交时间"> </el-table-column>
          </c-table>
        </div>
        <c-pagination :total="transferInfoTotal" :pageSize="10"></c-pagination>
      </div>
    </c-dialog>

    <!-- 分页 -->
    <div class="page">
      <c-pagination @change="changePage" :pageSize="propertyListParam.limit" :total="contactsTotal"></c-pagination>
    </div>
  </div>
</template>

<script>
import CDialog from '@/components/c-dialog.vue'
import * as contactsApi from '@/api/contacts.js'
import clone from '@/utils/clone.js'
import * as hrApi from '@/api/hr.js'
import _m from 'moment'
const addOrEditParamBase = {
  title: '',
  num: '',
  section_id: '',
  admin_id: '',
  mobile: '',
  remark: '',
  status: ''
}
export default {
  name: 'Property',
  components: {
    CDialog
  },
  data() {
    return {
      moment: '',
      navigation: [
        {
          id: 0,
          name: '闲置中',
          isChecked: true
        },
        {
          id: 1,
          name: '使用中',
          isChecked: false
        }
      ], // 列表信息
      propertyListParam: {
        status: 0,
        page: 1,
        limit: 7
      },
      contactsTotal: 0,
      addOrEditParam: clone(addOrEditParamBase),
      addOrEditType: '',
      editParam: {},
      transferParam: {
        property_id: '',
        section_id: '',
        admin_id: '',
        mobile: '',
        remark: '',
        images: ''
      },
      tableData: [],
      isAddOrEdit: false,
      isEdit: false,
      isTransfer: false,
      isTransferInfo: false,
      transferInfoList: [],
      transferInfoTotal: 0,
      transferInfoListParam: {
        page: 1,
        limit: 10
      },
      selectedNo: [],
      headList: []
    }
  },
  created() {
    this.moment = _m
    this.init()
  },
  methods: {
    /**
     * @description: 初始化
     */
    init() {
      this.getPropertyList()
      // this.getTransferInfoList()
    },
    /**
     * @description: 获取资产列表
     */
    getPropertyList() {
      hrApi.getPropertyList(this.propertyListParam).then(res => {
        this.tableData = res.data.data
        this.contactsTotal = res.data.total
      })
    },
    /**
     * @description: 改变财产状态
     */
    changePropertyState(v) {
      this.propertyListParam.status = v.id
      this.getPropertyList()
    },
    /**
     * @description: 打开新增资产
     * @return {*}
     */
    openAdd() {
      this.addOrEditType = '新增资产'
      this.isAddOrEdit = true
      this.addOrEditParam = clone(addOrEditParamBase)
    },
    /**
     * @description: 添加或编辑资产
     */
    addOrEditConfirm() {
      if (this.addOrEditType == '新增资产') {
        hrApi.addPropertyData(this.addOrEditParam).then(res => {
          this._showMsg('新增成功', 'success')
          this.getPropertyList()
          this.isAddOrEdit = false
        })
      } else if (this.addOrEditType == '编辑资产') {
        hrApi.editPropertyData(this.addOrEditParam).then(res => {
          this._showMsg('编辑成功', 'success')
          this.getPropertyList()
          this.isAddOrEdit = false
        })
      }
    },
    /**
     * @description: 获取资产移交列表
     */
    getTransferInfoList() {
      hrApi.pageListPropertyLog().then(res => {
        this.transferInfoList.push(...res.data.data)
      })
    },
    /**
     * @description: 打开移交的弹出层
     */
    openTransfer() {
      if (this.selectedNo.length == 1) {
        this.isTransfer = true
        this.transferParam.property_id = this.selectedNo[0]
      } else {
        this._showMsg('请选择一项')
      }
    },
    /**
     * @description: 修改添加资产的部门
     * @param {*} id: 修改后的部门id'
     */
    changeAddSection(id) {
      this.addOrEditParam.admin_id = ''
      this.addOrEditParam.mobile = ''
      this.getHead(id)
    },
    /**
     * @description: 修改移交财产的部门时调用
     * @param {*} id: 修改选项后的部门ID
     */
    changeTransferSection(id) {
      // this.transferParam.section_id = id
      this.transferParam.admin_id = ''
      this.transferParam.mobile = ''
      this.getHead(id)
    },
    /**
     * @description: 根据部门ID获取负责人列表
     * @param {*} id: 部门的id
     */
    getHead(id) {
      const param = {
        section_id: id,
        status: 'zaizhi',
        page: 1,
        limit: 999
      }
      contactsApi.getContacts(param).then(res => {
        this.headList = res.data.data
      })
    },
    /**
     * @description: 修改负责人的选项时根据ID重新为移交资产的参数重置数据
     * @param {*} id: 部门的id
     */
    changeTransferHead(id) {
      for (let i = 0; i < this.headList.length; i++) {
        const item = this.headList[i]
        if (item.id == id) {
          this.transferParam.mobile = item.mobile
          return
        }
      }
    },
    /**
     * @description: 修改负责人的选项时根据ID重新为移交资产的参数重置数据
     * @param {*} id: 部门的id
     */
    changeAddHead(id) {
      for (let i = 0; i < this.headList.length; i++) {
        const item = this.headList[i]
        if (item.id == id) {
          this.addOrEditParam.mobile = item.mobile
          return
        }
      }
    },
    /**
     * @description: 确定资产转移
     */
    confirmTransfer() {
      hrApi.addPropertyLog(this.transferParam).then(res => {
        this._showMsg('资产移交成功', 'success')
        this.isTransfer = false
        this.getPropertyList()
      })
    },
    /**
     * @description: 改变选中的资产
     * @param {*} item: 选中的资产数据
     */
    changeNo(item) {
      const i = this.selectedNo.indexOf(item.id)
      if (i !== -1) {
        this.selectedNo.splice(i, 1)
      } else {
        this.selectedNo.push(item.id)
      }
    },
    /**
     * @description: 编辑资产
     * @param {*} item: 资产数据
     */
    editAssets(item) {
      hrApi.getPropertyInfo({ id: item.id }).then(res => {
        for (const item in this.addOrEditParam) {
          this.addOrEditParam[item] = res.data[item]
        }
        this.addOrEditParam.id = res.data.id
        if (this.addOrEditParam.status == '闲置中') {
          this.addOrEditParam.status = 0
        } else if (this.addOrEditParam.status == '使用中') {
          this.addOrEditParam.status = 1
        }
        this.addOrEditType = '编辑资产'
        this.isAddOrEdit = true
        this.getHead(item.section_id)
      })
    },
    /**
     * 关键词搜索
     * @description: 客户查询
     * @param {*} e: 要查询的条件
     */
    clientSearchKey(e) {
      this.getPropertyList()
    },
    /**
     * 移交信息列表时间查询
     * @description: 客户查询
     * @param {*} e: 要查询的条件
     */
    clientSearchDate(e) {
      // this.listParam.usmo = e.keyWord
      // this.getList()
    },
    /**
     * 跳转页数
     */
    changePage(i) {
      this.propertyListParam.page = i
      this.getPropertyList()
    }
  }
}
</script>
<style>
.el-form-item {
  display: flex !important;
}
.el-form-item__label {
  font-size: 0.94rem;
  padding: 0;
  flex-shrink: 0;
}
.el-form-item__content {
  flex-grow: 1;
}
</style>
<style lang="scss" scoped>
.property-page {
  background-color: #fff;
}
.btn-group {
  .transfer {
    background: linear-gradient(109deg, #099cfa, #56b7f5);
  }
  .add {
    background: linear-gradient(109deg, #fc9940, #f37902);
  }
}
.transferInfo {
  .data {
    display: flex;
    align-items: center;
    justify-content: center;

    .txt {
      font-size: 0.83rem;
      font-weight: 400;
      color: #333333;
    }
  }
  .table-container {
    margin: 2.8rem 0 0;
  }
}
</style>
